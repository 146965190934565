let monthsNominative = 'Ιανουάριος_Φεβρουάριος_Μάρτιος_Απρίλιος_Μάιος_Ιούνιος_Ιούλιος_Αύγουστος_Σεπτέμβριος_Οκτώβριος_Νοέμβριος_Δεκέμβριος'.split('_');
let monthsGenitive = 'Ιανουαρίου_Φεβρουαρίου_Μαρτίου_Απριλίου_Μαΐου_Ιουνίου_Ιουλίου_Αυγούστου_Σεπτεμβρίου_Οκτωβρίου_Νοεμβρίου_Δεκεμβρίου'.split('_');

export const el = {
  months: (dayjsToFormat, format) => {
    if (/\DD/.test(format.substring(0, format.indexOf('MMM')))) {
      // if there is a day number before 'MMMM'
      return monthsGenitive[dayjsToFormat.month()];
    } else {
      return monthsNominative[dayjsToFormat.month()];
    }
  },
  monthsNominative,
  monthsGenitive,
  formats: {
    ll: 'ddd, D MMM',
    lll: 'ddd, D MMMM',
    llll: 'ddd, D MMMM YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'ddd, D MMM YYYY',
  },
  monthsShort: 'Ιαν_Φεβ_Μαρ_Απρ_Μαϊ_Ιουν_Ιουλ_Αυγ_Σεπ_Οκτ_Νοε_Δεκ'.split('_'),
  weekdays: 'Κυριακή_Δευτέρα_Τρίτη_Τετάρτη_Πέμπτη_Παρασκευή_Σάββατο'.split('_'),
  weekdaysShort: 'Κυρ_Δευ_Τρι_Τετ_Πεμ_Παρ_Σαβ'.split('_'),
  weekdaysMin: 'Κυ_Δε_Τρ_Τε_Πε_Πα_Σα'.split('_'),
  weekdaysDatepicker: 'Δευ_Τρι_Τετ_Πεμ_Παρ_Σαβ_Κυρ'.split('_'),
};
