export const it = {
  months: 'gennaio_febbraio_marzo_aprile_maggio_giugno_luglio_agosto_settembre_ottobre_novembre_dicembre'.split('_'),
  monthsShort: 'gen_feb_mar_apr_mag_giu_lug_ago_set_ott_nov_dic'.split('_'),
  weekdays: 'domenica_lunedì_martedì_mercoledì_giovedì_venerdì_sabato'.split('_'),
  weekdaysShort: 'dom_lun_mar_mer_gio_ven_sab'.split('_'),
  weekdaysMin: 'do_lu_ma_me_gi_ve_sa'.split('_'),
  weekdaysDatepicker: 'lun_mar_mer_gio_ven_sab_dom'.split('_'),
  formats: {
    ll: 'ddd D MMM',
    lll: 'ddd D MMMM',
    llll: 'ddd D MMMM YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'ddd D MMM YYYY',
  },
};
