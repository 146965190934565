/* eslint-disable no-useless-escape */

import { each as _each, orderBy as _orderBy } from 'lodash-es';
import { storageGet, storageSave } from '@/logic/services/storage/Storage';
import { removeAllSpaces } from './stringUtils';

/**
 * Returns a rounded number to given decimal places
 * @param {String|Number} input The string input to parse
 * @param {number} DecimalPlaces Number of decimal places
 */
export const roundPrice = (input, DecimalPlaces) => {
  return Math.round(parseFloat(input) * Math.pow(10, DecimalPlaces)) / Math.pow(10, DecimalPlaces);
};

//--------------------------------------------------------------------------
// cleans a vue item by converting it to json and back
export const clean = (item) => {
  try {
    return JSON.parse(JSON.stringify(item));
  } catch (e) {
    return '';
  }
};

/**
 * This is a multipurpose function for creating friendly conctatenated string of passengers, companies etc.
 * It receives an array of names, and returns a friendly concatenation of it.
 * Eg. ['A', 'B', 'C'] will become 'A, B & C'
 *
 * However, if instead of strings, some values are numbers, this means that we want to use it for array
 * of passengers without names. These always come in front, also considering single or plural.
 * eg1. ['Billias', 0, 1] will become 'Passengers 0, 1 & Billias
 * eg2. ['Billias', 0] will become 'Passenger 0 & Billias
 *
 * @param {String[]} arrIn
 * @param {String} separator
 * @param {String} lastSeparator
 */
export const joinNamesWithSeparator = (arrIn, singular, plural) => {
  // bring the values that are numbers in front (eg. ['something', 0, 2] => [0, 2, 'something])
  let sortedWithNamesLast = _orderBy(arrIn, (o) => isNaN(o));

  // get the elements that are numbers, hence no name (eg. [0, 2, 'something'] => ['0', '1'])
  const withoutName = arrIn.filter((o) => !isNaN(o));

  // remove last item
  const lastItem = sortedWithNamesLast.splice(-1);
  let finalArray = sortedWithNamesLast.length > 0 ? [sortedWithNamesLast.join(', ')] : [];
  finalArray.push(lastItem);

  switch (withoutName.length) {
    case 0:
      return finalArray.join(` ${message('generic.ampersand')} `);
    case 1:
      return `${singular} ${finalArray.join(` ${message('generic.ampersand')} `)}`;
    default:
      return `${plural} ${finalArray.join(` ${message('generic.ampersand')} `)}`;
  }
};

/**
 * Creates a unique id of a given length
 */
export function uniqueId(length) {
  return Math.random().toString(36).substr(2, length);
}

// Generates a v4 UUID using a cryptographically secure random number generator.
export const generateUUIDv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
};

// Generates a persistent guest user id
export const generateGuestUserId = () => {
  const guestUserId = storageGet('fh.guestUserId', true) || generateUUIDv4();
  storageSave('fh.guestUserId', guestUserId, true);
};

/**
 * Returns true if an object it defined and not null
 * @param {any} obj
 */
export const notNull = (obj) => {
  return isNull(obj) === false;
};

/**
 * Returns true if an object is undefined or null
 * @param {any} obj
 */
export const isNull = (obj) => {
  return typeof obj === 'undefined' || obj === undefined || obj === null;
};

export const isNullOrEmptyString = (str) => {
  return isNull(str) || '' === str;
};

/**
 * Returns true if an object is empty
 * @param {any} obj
 */
export const isEmpty = (obj) => {
  return Object.entries(obj).length === 0;
};

/**
 * Translates a key from window object, or returns the fallback, which can be undefined
 * @param {String} key
 * @param {String|undefined} fallback
 */
const translateKey = (key, fallback) => (window.messages && window.messages[key]) || fallback;

/**
 * Returns a translated string according to a given input, and as a fallback
 * it returns the input
 *
 * @param {string} key
 */
export const message = (key) => translateKey(key, key);
export const fhTranslate = (key) => message(key);

/**
 *
 */
export const isJsonPropTrue = (v) => v === '1' || v === 1 || v === true;

/**
 * Returns an global configuration values array from a comma separated string,
 * with a fallback
 *
 * @param {string} propertyName
 * @param {any} defaultValue
 */
export const getConfigurationArray = (propertyName, defaultValue) => {
  if (!window.ferryhopper) return defaultValue;
  if (!window.ferryhopper[propertyName]) return defaultValue;
  let commaSeparatedString = window.ferryhopper[propertyName];
  return commaSeparatedString.split(',').map(removeAllSpaces);
};

/**
 * Returns a global configuration value with a default fallback
 *
 * @param {string} propertyName
 * @param {string} defaultValue
 */
export const getConfigurationValue = (propertyName, defaultValue) => {
  return (window.ferryhopper && window.ferryhopper[propertyName]) || defaultValue;
};

/**
 * Returns numeric configuration value
 * @param {string} propertyName
 * @param {string} defaultValue
 */
export const getConfigurationNumber = (propertyName, defaultValue) => Number(getConfigurationValue(propertyName, defaultValue));

/**
 * Returns the current page language
 */
export const getPageLanguage = () => getConfigurationValue('fh_page_lang', 'en');

/**
 * Returns whether the view is mobile.
 */
export const isMobile = () => {
  if (!window) return false;
  if (!window.ferryhopper) return false;
  return !!window.ferryhopper.is_mobile;
};

export const getWindowUrlOrigin = () => window.location.origin;

/**
 * Returns current page's name for google analytics
 */
export const getPageName = () => {
  const url = window.location.href;
  if (url.includes('ferry-routes/')) return 'Ferry route';
  if (url.includes('ferry-routes')) return 'Ferry routes (Home)';
  if (url.includes('my-booking') || url.includes('mybooking')) return 'My booking';
  if (url.includes('profile')) return 'User Profile';
  if (url.includes('#search')) return 'Search';
  if (url.includes('#results')) return 'Results';
  if (url.includes('#passengers')) return 'Ticket Options';
  if (url.includes('#book')) return 'Passenger details';
  if (url.includes('payment')) return 'Payment page';
  if (url.includes('vessels/')) return 'Vessel Page';
  if (url.includes('ferry-operators/')) return 'Ferry operator';
  if (url.includes('ferry-operators')) return 'Ferry operators (Home)';
  if (url.includes('destinations/')) return 'Destinations';
  if (url.includes('destinations')) return 'Destinations (Home)';
  if (url.includes('faq')) return 'AMP - FAQ';
  if (url.includes('special-offers/')) return 'Ferry offers';
  if (url.includes('special-offers')) return 'Ferry offers (Home)';
  if (url.includes('blog/')) return 'Blog post';
  if (url.includes('blog')) return 'Blog (Home)';
  if (url.includes('about')) return 'About us';
  if (url.includes('contact')) return 'Contact us';
  if (url.includes('terms')) return 'Terms of use';
  if (url.includes('privacy-policy')) return 'Privacy policy';
  if (url.includes('cookies')) return 'Cookies';
  if (url.includes('ferries-map')) return 'Map of ferries';
  if (url.includes('mobile-application')) return 'Mobile application';
  if (url.includes('ferry-timetables/')) return 'Ferry timetables';
  return 'Home';
};
