export const da = {
  weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split('_'),
  weekdaysShort: 'Søn_Man_Tirs_Ons_Tors_Fre_Lør'.split('_'),
  weekdaysMin: 'sø._ma._ti._on._to._fr._lø.'.split('_'),
  months: 'januar_februar_marts_april_maj_juni_juli_august_september_oktober_november_december'.split('_'),
  monthsShort: 'jan_feb_mar_apr_maj_juni_juli_aug_sept_okt_nov_dec'.split('_'),
  weekdaysDatepicker: 'man_tirs_ons_tors_fre_lør_søn'.split('_'),
  formats: {
    ll: 'ddd D. MMM',
    lll: 'ddd D. MMMM',
    llll: 'ddd D. MMMM YYYY',
    LL: 'D. MMMM YYYY',
    LLL: 'ddd D. MMM YYYY',
  },
};
