export const nl = {
  weekdays: 'zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag'.split('_'),
  weekdaysShort: 'zo_ma_di_wo_do_vr_za'.split('_'),
  weekdaysMin: 'zo_ma_di_wo_do_vr_za'.split('_'),
  months: 'januari_februari_maart_april_mei_juni_juli_augustus_september_oktober_november_december'.split('_'),
  monthsShort: 'jan_feb_mrt_apr_mei_jun_jul_aug_sep_okt_nov_dec'.split('_'),
  weekdaysDatepicker: 'ma_di_wo_do_vr_za_zo'.split('_'),
  formats: {
    ll: 'ddd D MMM',
    lll: 'ddd D MMMM',
    llll: 'ddd D MMMM YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'ddd D MMM YYYY',
  },
};
