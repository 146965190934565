export const sv = {
  weekdays: 'söndag_måndag_tisdag_onsdag_torsdag_fredag_lördag'.split('_'),
  weekdaysShort: 'sön_mån_tis_ons_tor_fre_lör'.split('_'),
  weekdaysMin: 'sö_må_ti_on_to_fr_lö'.split('_'),
  months: 'januari_februari_mars_april_maj_juni_juli_augusti_september_oktober_november_december'.split('_'),
  monthsShort: 'jan_feb_mar_apr_maj_jun_jul_aug_sep_okt_nov_dec'.split('_'),
  weekdaysDatepicker: 'mån_tis_ons_tor_fre_lör_sön'.split('_'),
  formats: {
    ll: 'ddd, D MMM',
    lll: 'ddd, D MMMM',
    llll: 'ddd, D MMMM YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'ddd, D MMM YYYY',
  },
};
