/* eslint-disable no-undef */
import { initialize as brazeInitialize, openSession as brazeOpenSession, changeUser as brazeChangeUser } from '@braze/web-sdk';
import { storageGet, storageSave } from '@/logic/services/storage/Storage';
import { notNull } from '@/logic/helpers/utils';

const isBrazeConfigDefined = () => notNull(window.ferryhopper) && notNull(window.ferryhopper.braze);

/**
 * Checks if braze session logging (the 1st level of logging) is enabled
 * by checking window feature flags
 * 
 * @returns {Boolean}
 */
export const isBrazeSessionLoggingEnabled = () => {
  if (!isBrazeConfigDefined()) return false;
  const { sessionLogging } = window.ferryhopper.braze;
  return sessionLogging;
};

/**
 * Checks if braze eveng logging (the 2nd level of logging) is enabled
 * by checking window feature flags
 * 
 * @returns {Boolean}
 */
export const isBrazeEventLoggingEnabled = () => {
  if (!isBrazeConfigDefined()) return false;
  const { eventLogging } = window.ferryhopper.braze;
  return eventLogging;
};

/**
 * Returns true if the user has accepted marketing cookies (or all cookies)
 * through our cookiebot (see: https://www.cookiebot.com/en/developer/)
 * 
 * @returns {Boolean}
 */
export const hasMarketingConsent = () => {
  try {
    if (Cookiebot && Cookiebot.consent.marketing) return true;
    return false;
  } catch {
    return false;
  }
};

/**
 * Starts a braze session 
 * 
 * @returns {Boolean}
 */
export const startBrazeSession = (incomingExternalId) => {
  if (!hasMarketingConsent()) return;
  if (isBrazeInitializedForSession()) return;

  initializeBraze(incomingExternalId);
};


const initializeBraze = (incomingExternalId) => {
  if (!hasMarketingConsent()) return;

  if (!isBrazeSessionLoggingEnabled()) return;


  initializeBrazeSdk();

  initializeBrazeSession();

  const { externalId: windowExternalId } = window.ferryhopper.braze;
  const userExternalId = incomingExternalId || windowExternalId;
  if (userExternalId) initializeBrazeUser(userExternalId);

  initializeBrazeForSession();
};

/**
 * Initialize braze sdk
 */
const initializeBrazeSdk = () => {  
  const { apiKey, baseUrl, enableLogging } = window.ferryhopper.braze;
  brazeInitialize(apiKey, { baseUrl, enableLogging });
}

/**
 * Open braze session
 */
const initializeBrazeSession = () => brazeOpenSession();

/**
 * Set external id for braze user
 * @param {String} externalId 
 */
const initializeBrazeUser = (externalId) => brazeChangeUser(externalId);

/**
 * Checks if Braze is initialized for the current user session session.
 *
 * @returns {boolean}
 */
export const isBrazeInitializedForSession = () => storageGet('fh.brazeInitialized', false) || false;

/**
 * Initializes Braze for the current user session.
 */
export const initializeBrazeForSession = () => storageSave('fh.brazeInitialized', true, false);

/**
 * Resets Braze initialization status for the current user session.
 */
export const resetIsBrazeInitializedForSession = () => storageSave('fh.brazeInitialized', false, false);