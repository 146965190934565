/* eslint-disable no-undef */

/**
 * Capture an exception with Sentry
 * @param {string} message
 * @param {Object} data
 */
export const logException = (message, data) => {
  try {
    if (!Sentry) return;
    Sentry.captureEvent({
      message,
      extra: data
    });
  } catch (sentryException) {
    // silent catch of either sentry not being loaded, 
    // or failing to accept events
  }
};
