import dayjs from '@/logic/services/date/dateService';

export const brazeEventAddToCartPayload = trips => {

  const tripsForPayload = trips.reduce((acc, trip, index) => {
    acc[`trip_${index + 1}`] = {
      origin_port: trip.Details.DepStationName,
      destination_port: trip.Details.ArrStationName,
      datetime_of_departure: dayjs(trip.timings.DepartureDateTime).toISOString()
    };
    return acc;
  }, {});

  return {
    continue_url: window.location.href,
    booking_platform: 'WEB',
    trips: tripsForPayload
  };
};

