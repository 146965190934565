let monthsNominative = 'siječanj_veljača_ožujak_travanj_svibanj_lipanj_srpanj_kolovoz_rujan_listopad_studeni_prosinac'.split('_');
let monthsSubjective = 'siječnja_veljače_ožujka_travnja_svibnja_lipnja_srpnja_kolovoza_rujna_listopada_studenoga_prosinca'.split('_');

export const hr = {
  months: function (dateToFormat, format) {
    if (!dateToFormat) return monthsNominative;
    if (format === '') return '(' + monthsSubjective[dateToFormat.month()] + '|' + monthsNominative[dateToFormat.month()] + ')';
    if (/D MMMM/.test(format)) return monthsSubjective[dateToFormat.month()];
    return monthsNominative[dateToFormat.month()];
  },
  monthsNominative,
  monthsSubjective,
  weekdays: 'nedjelja_ponedjeljak_utorak_srijeda_četvrtak_petak_subota'.split('_'),
  weekdaysShort: 'ned_pon_uto_sri_čet_pet_sub'.split('_'),
  weekdaysMin: 'ne_po_ut_sr_če_pe_su'.split('_'),
  monthsShort: 'sij_velj_ožu_tra_svi_lip_srp_kol_ruj_lis_stu_pro'.split('_'),
  weekdaysDatepicker: 'pon_uto_sri_čet_pet_sub_ned'.split('_'),
  formats: {
    ll: 'ddd, D MMM',
    lll: 'ddd, D MMM',
    llll: 'ddd, D MMMM YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'ddd, D MMM YYYY',
  },
};
