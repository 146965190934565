export const de = {
  months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
  monthsShort: 'Jan_Feb_März_Apr_Mai_Juni_Juli_Aug_Sept_Okt_Nov_Dez'.split('_'),
  weekdays: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
  weekdaysShort: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
  weekdaysMin: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
  weekdaysDatepicker: 'Mo_Di_Mi_Do_Fr_Sa_So'.split('_'),
  formats: {
    ll: 'dd, D. MMM',
    lll: 'dd, D. MMMM',
    llll: 'ddd, D. MMMM YYYY',
    LL: 'D. MMMM YYYY',
    LLL: 'ddd, D. MMM YYYY',
  },
};
