export const fr = {
  months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
  monthsShort: 'janv._févr._mars_avr._mai_juin_juill._août_sept._oct._nov._déc.'.split('_'),
  weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin: 'di_lu_ma_me_je_ve_sa'.split('_'),
  weekdaysDatepicker: 'lun_mar_mer_jeu_ven_sam_dim'.split('_'),
  formats: {
    ll: 'ddd D MMM',
    lll: 'ddd D MMMM',
    llll: 'ddd D MMMM YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'ddd D MMM YYYY',
  },
};
