import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import duration from 'dayjs/plugin/duration';
import updateLocale from 'dayjs/plugin/updateLocale';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
// Locale related modules
import 'dayjs/locale/bg';
import 'dayjs/locale/de';
import 'dayjs/locale/el';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/hr';
import 'dayjs/locale/sq';
import 'dayjs/locale/tr';
import 'dayjs/locale/sv';
import 'dayjs/locale/da';
import 'dayjs/locale/nl';

let locale = window.ferryhopper.fh_page_lang;

// Initialize day.js for current language.
dayjs.locale(locale);

// Support for custom formats of input strings (for available options see: https://day.js.org/docs/en/parse/string-format#list-of-all-available-parsing-tokens).
dayjs.extend(customParseFormat);

// Support for advanced formatting options (reference: https://day.js.org/docs/en/plugin/advanced-format).
dayjs.extend(advancedFormat);

// Support for finding if a date is between two other dates.
dayjs.extend(isBetween);

// Support for parsing and display in UTC (reference: https://day.js.org/docs/en/plugin/utc).
dayjs.extend(utc);

// Support for indicating whether the Day.js object is today or not.
dayjs.extend(isToday);

// Support for calculating duration.
dayjs.extend(duration);

// Support for updating properties of a locale (reference: https://day.js.org/docs/en/customization/customization).
dayjs.extend(updateLocale);

// Support for accessing locale related data
dayjs.extend(localeData);

// Support for accessing localized format options
dayjs.extend(localizedFormat);

// Add custom options for every locale
import { bg } from './locales/bg';
import { de } from './locales/de';
import { el } from './locales/el';
import { en } from './locales/en';
import { es } from './locales/es';
import { fr } from './locales/fr';
import { it } from './locales/it';
import { pl } from './locales/pl';
import { pt } from './locales/pt';
import { hr } from './locales/hr';
import { sq } from './locales/sq';
import { tr } from './locales/tr';
import { sv } from './locales/sv';
import { da } from './locales/da';
import { nl } from './locales/nl';

if (locale === 'bg') dayjs.updateLocale('bg', bg);
if (locale === 'de') dayjs.updateLocale('de', de);
if (locale === 'el') dayjs.updateLocale('el', el);
if (locale === 'en') dayjs.updateLocale('en', en);
if (locale === 'es') dayjs.updateLocale('es', es);
if (locale === 'fr') dayjs.updateLocale('fr', fr);
if (locale === 'it') dayjs.updateLocale('it', it);
if (locale === 'pl') dayjs.updateLocale('pl', pl);
if (locale === 'pt') dayjs.updateLocale('pt', pt);
if (locale === 'hr') dayjs.updateLocale('hr', hr);
if (locale === 'sq') dayjs.updateLocale('sq', sq);
if (locale === 'tr') dayjs.updateLocale('tr', tr);
if (locale === 'sv') dayjs.updateLocale('sv', sv);
if (locale === 'da') dayjs.updateLocale('da', da);
if (locale === 'nl') dayjs.updateLocale('nl', nl);

// Export a dayjs function. When this function gets called, it will create a wrapper object
// which is immutable i.e. operations that change the Day.js object in some way will return a new
// instance of it (reference: https://day.js.org/docs/en/parse/parse)
export default dayjs;
