import { logException } from '@/logic/services/events/errorLogging'

/**
 * This module serves is used to store and retrieve data from the session
 * or local storage of the user's browser.
 *
 * If for any reason, these two storage options are not available, it creates
 * a global window variable (ferryhopper_temporary_storage) and keeps things
 * in there.
 *
 * todo: storage plays an important role in our application. however, it appear
 * that it is not supported by some browsers, and this can lead to undesired
 * effects. The main issue that we should address, is what the user will see
 * on a browser that does not support localStorage, when he returns from the
 * bank page after cancelling a payment.
 */
export const storageSave = (name, data, permanent) => {
  try {
    if ((typeof permanent !== 'undefined') && (permanent === true)) {
      localStorage.setItem(name, JSON.stringify(data));
    } else {
      sessionStorage.setItem(name, JSON.stringify(data));
    }
  } catch (e) {
    if (typeof window.ferryhopper_temporary_storage === 'undefined') {
      window.ferryhopper_temporary_storage = {};
    }
    window.ferryhopper_temporary_storage[name] = JSON.stringify(data);
  }
}

export const storageGet = (name, permanent) => {
  try {
    if ((typeof permanent !== 'undefined') && (permanent === true)) {
      return JSON.parse(localStorage.getItem(name));
    } else {
      return JSON.parse(sessionStorage.getItem(name));
    }
  } catch (e) {
    logException('Unable to load local storage', {
      exception: e,
      name
    });
    return window.ferryhopper_temporary_storage && window.ferryhopper_temporary_storage[name] || null;
  }
}
