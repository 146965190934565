let monthsNominative = 'styczeń_luty_marzec_kwiecień_maj_czerwiec_lipiec_sierpień_wrzesień_październik_listopad_grudzień'.split('_');
let monthsSubjective = 'stycznia_lutego_marca_kwietnia_maja_czerwca_lipca_sierpnia_września_października_listopada_grudnia'.split('_');

export const pl = {
  months: function (dateToFormat, format) {
    if (!dateToFormat) return monthsNominative;
    if (format === '') return '(' + monthsSubjective[dateToFormat.month()] + '|' + monthsNominative[dateToFormat.month()] + ')';
    if (/D MMMM/.test(format)) return monthsSubjective[dateToFormat.month()];
    return monthsNominative[dateToFormat.month()];
  },
  monthsNominative,
  monthsSubjective,
  monthsShort: 'sty_lut_mar_kwi_maj_cze_lip_sie_wrz_paź_lis_gru'.split('_'),
  weekdays: 'niedziela_poniedziałek_wtorek_środa_czwartek_piątek_sobota'.split('_'),
  weekdaysShort: 'Niedz_Pon_Wt_Śr_Czw_Pt_Sob'.split('_'),
  weekdaysMin: 'nd_pn_wt_śr_cz_pt_so'.split('_'),
  weekdaysDatepicker: 'pn_wt_śr_czw_pt_sob_nd'.split('_'),
  formats: {
    ll: 'ddd, D MMM',
    lll: 'ddd, D MMMM',
    llll: 'ddd, D MMMM YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'ddd, D MMM YYYY',
  },
};
