import { logCustomEvent as brazeLogCustomEvent } from '@braze/web-sdk';
import { brazeEventAddToCartPayload } from '@/logic/services/crm/brazeEventsPayloads';
import { isBrazeEventLoggingEnabled, isBrazeInitializedForSession } from './braze';

// Send a custom event using the braze sdk (https://www.braze.com/docs/developer_guide/platform_integration_guides/web/analytics/tracking_custom_events)
export const brazeSendEvent = (eventName, eventPayload) => {
  if (!isBrazeInitializedForSession()) return;
  if (!isBrazeEventLoggingEnabled()) return;
  brazeLogCustomEvent(eventName, eventPayload);
};

export const brazeEventAddToCart = payload => brazeSendEvent('added_to_cart', brazeEventAddToCartPayload(payload));
