export const REGEX_MATCH_ALL_WHITESPACES = /\s+/g;

export const REGEX_ALL_NON_LATIN_AND_NON_DIGITS = /[^A-Za-z0-9\s]/g;

export const REGEX_ALL_NON_DIGITS = /\D/g;

export const REGEX_MATCH_STARTING_PLUS_SIGN = /^\+/g;

export const REGEX_MATCH_ALL_WHITESPACES_DASHES_AND_PLUS = /[-+\s]/g;

export const REGEX_MATCH_ALL_SPACES = / /g;

export const REGEX_MAX_CONSECUTIVE_WHITESPACES = /\s\s+/g;

export const LATIN_ALPHA_SPACES = /^[A-Z\s]*$/i;

export const PHONE_VALIDATION_REGEX = /^[0-9+-]+$/;

export const LATIN_ALPHA_SPACES_NUM = /^[a-zA-Z0-9\s]*$/;

export const REGEX_ALPHA_NUM_NO_SPECIAL_CHARACTERS = /[^\w\s\u00C0-\u017F\u0180-\u024F\u1e02-\u1ef3\u0370-\u03FF\u0400-\u04FF.,:()!?-]+/;

export const REGEX_QUERY_PARAMETERS_KEY_VALUES = /[?&]+([^=&]+)=([^&]*)/gi;

export const REGEX_FIND_WORD_DAY = /\b(?:day)\b/gi;

export const EMAIL_REGEX = /\S+@\S+\.\S+/;

export const NUMERIC_REGEX = /^\d+$/;

export const NUMERIC_ALLOW_DASHES_REGEX = /^[\d-]+$/;
