export const sq = {
  weekdays: 'E Diel_E Hënë_E Martë_E Mërkurë_E Enjte_E Premte_E Shtunë'.split('_'),
  months: 'janar_shkurt_mars_prill_maj_qershor_korrik_gusht_shtator_tetor_nëntor_dhjetor'.split('_'),
  weekdaysShort: 'Die_Hën_Mar_Mër_Enj_Pre_Sht'.split('_'),
  monthsShort: 'jan_shk_mar_pri_maj_qer_kor_gus_sht_tet_nën_dhj'.split('_'),
  weekdaysMin: 'D_H_Ma_Më_E_P_Sh'.split('_'),
  weekdaysDatepicker: 'Hën_Mar_Mër_Enj_Pre_Sht_Die'.split('_'),
  formats: {
    ll: 'ddd, D MMM',
    lll: 'ddd, D MMM',
    llll: 'ddd, D MMMM YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'ddd, D MMM YYYY',
  },
};
