export const bg = {
  months: 'януари_февруари_март_април_май_юни_юли_август_септември_октомври_ноември_декември'.split('_'),
  monthsShort: 'яну_фев_март_апр_май_юни_юли_авг_септ_окт_ное_дек'.split('_'),
  weekdays: 'неделя_понеделник_вторник_сряда_четвъртък_петък_събота'.split('_'),
  weekdaysShort: 'нед_пон_вто_сря_четв_петък_съб'.split('_'),
  weekdaysMin: 'нд_пн_вт_ср_чт_пт_сб'.split('_'),
  weekdaysDatepicker: 'пн_вт_ср_чт_пт_сб_нд'.split('_'),
  formats: {
    ll: 'ddd, D MMM',
    lll: 'ddd, D MMMM',
    llll: 'ddd, D MMMM YYYY г.',
    LL: 'D MMMM YYYY г.',
    LLL: 'ddd, D MMM YYYY г.',
  },
};
