export const pt = {
  months: 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split('_'),
  monthsShort: 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_'),
  weekdays: 'Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sábado'.split('_'),
  weekdaysShort: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
  weekdaysMin: 'Do_2ª_3ª_4ª_5ª_6ª_Sá'.split('_'),
  weekdaysDatepicker: 'Seg_Ter_Qua_Qui_Sex_Sáb_Dom'.split('_'),
  formats: {
    ll: 'ddd, D MMM',
    lll: 'ddd, D MMMM',
    llll: 'ddd, D MMMM YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'ddd, D MMM YYYY',
  },
};
