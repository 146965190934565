export const en = {
  formats: {
    ll: 'ddd, D MMM',
    lll: 'ddd, D MMMM',
    llll: 'ddd, D MMMM YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'ddd, D MMM YYYY',
  },
  weekdaysDatepicker: 'mon_tue_wed_thu_fri_sat_sun'.split('_'),
};
