import { REGEX_MATCH_ALL_SPACES, REGEX_MATCH_ALL_WHITESPACES, REGEX_MAX_CONSECUTIVE_WHITESPACES } from './regexes';
import { notNull } from './utils';

/**
 * Removes trailing spaces and converts to uppercase
 * @param {string} str
 */
export const trimAndUppercase = str => str.trim().toUpperCase();

/**
 * Removes all spaces from a string
 * @param {string} strIn
 */
export const removeAllSpaces = strIn => strIn.replace(REGEX_MAX_CONSECUTIVE_WHITESPACES, ' ').replace(REGEX_MATCH_ALL_SPACES, '');

/**
 * converts spaces to - in a phone number
 * Used to convert phones as the user types
 * @param {string} phoneNumber
 */
export const replaceSpaceWithDash = phoneNumber => {
  if (notNull(phoneNumber) && phoneNumber !== '') return phoneNumber.replace(REGEX_MATCH_ALL_WHITESPACES, '-');
};

/**
 * Converts minutes in duration string (eg. 90 minutes => 1h30m)
 */
export const minutesToDurationStr = (minutes, separator) => {
  if (typeof separator === 'undefined') {
    separator = ' ';
  }

  let days = parseInt(minutes / 24 / 60);
  let hours = parseInt((minutes / 60) % 24);
  let mins = parseInt(minutes % 60);
  let parts = [];

  // add dates
  if (days > 0) parts.push(days + 'd');

  // add hours
  if (hours > 0) parts.push(hours + 'h');

  // add minutes only if dates are zero (to avoid having minute-resolution on trips
  // that last for +1 days)
  if (days === 0 && mins > 0) parts.push(mins + 'm');

  return parts.join(separator);
};

/**
 * Converts a sentence into title-case
 * @param  {[type]} text [description]
 * @return {[type]}      [description]
 */
export const titleCase = text => {
  if (!text) return text;
  if (typeof text !== 'string') throw 'invalid argument';

  return text
    .toLowerCase()
    .split(' ')
    .map(value => {
      return value.charAt(0).toUpperCase() + value.substring(1);
    })
    .join(' ');
};

export const removeColon = timeStr => timeStr.replace(':', '');

// Converts a string to its decomposed Unicode form e.g an i with an accent, is coverted to an 'i'
// plus an accent (2 symbols). A regex is then used to remove the accent (and generally all diacritics).
// Reference: https://www.davidbcalhoun.com/2019/matching-accented-strings-in-javascript/
export const convertAccentedString = text => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

/**
 * Check if a string is a valid url
 * @param {string} string
 * @returns {boolean}
 */
export const stringIsValidUrl = string => {
  // If the string is not a valid url, a TypeError exception is thrown.
  try {
    const url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch {
    return false;
  }
};


export const getStringCharAtIndex = (str, index, fallbackChar) => {
  const foundChar = str.charAt(index);
  return foundChar !== '' ? foundChar : fallbackChar;
}